import React from 'react';
import { NavLink } from 'react-router-dom';
import Crevette from '../images/imageCrevettes.jpeg';

const Pizza = () => {
    return (
        <div>
        <div  style={{ backgroundImage:`url(${Crevette})` }}>
        <br/>
        <div className="container">
        <div className="green lighten-5" style={{borderRadius: 35, paddingBottom: 15}}>
            
            
                <h4 className="center green-text text-darken-2" style={{paddingTop: 15}}>Pizzas</h4>

                <table>
                    <tbody>

                        <tr style={{border: "none"}}> 
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Margarita</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 13.50</td>
                        </tr>
                            <tr>
                                <td style={{paddingLeft: 15}}>Tomates, mozzarella, épices</td>
                                <td></td>
                            </tr>
  
    
                        

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Napoli</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 17.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, anchoix, câpres</td>
                            <td></td>
                        </tr>
                        

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Veneziana</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 17.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, champignons, jambon d'épaule</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Sicilienne</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 18.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, poivrons, jambon d'épaule, oeuf</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Enzo</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 17.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, poivrons, origan, légumes, olives</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Mirabel
                            </td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 18.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, thon, oignons, câpres, olives, origan</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Dynamite forces 1-2-3</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 18.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, merguez, jambon d'épaule</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Capricciosa</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 18.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, thon, champignons, poivrons, jambon d'épaule</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Quatre saisons</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 19.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, thon, champignons, poivrons, jambon d'épaule</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Fruits de mer</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 20.00</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, moules, vongoles, calamars, poulpe, crevettes</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Paysanne</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 18.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, lard, oignons, ail, oeuf</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Hawaï</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 18.50</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft: 15}}>Tomates, mozzarella, jambon épaule, ananas, curry</td>
                            <td></td>
                        </tr>

                        <tr style={{border: "none"}}>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Francis</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 20.00</td>
                        </tr>
                        <tr>
                            <td style={{borderBot:"1px", paddingLeft: 15}}>Tomates, mozzarella di Bufala, jambon cru</td>
                            <td></td>
                        </tr>

                    </tbody>
                </table>

                <br/>

                <NavLink to="/menuSaisonnier">
                    <button className="btn green darken-2 waves-effect waves-dark">Menus saisonniers</button>
                </NavLink>

                <br/><br/>

            </div>
            <br/><br/>
            </div>
            </div>
            
            </div>

        
    )
}

export default Pizza;