import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';


class Navbar extends Component {

    componentDidMount() {
        let dropdown = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(dropdown, {});
        let sidenav = document.querySelector('#slide-out');
        M.Sidenav.init(sidenav, {});
      }

    render() {
    return (
        <nav className="green darken-2">
            

            

        <div style={{ opacity:'99%'}}>
                <a className="sidenav-trigger" data-target="slide-out">
                    <i className="material-icons">menu</i>
                </a>
                </div>
            
                
                
                    {/* Cache pour les portables et tablette*/}
                    
                    <ul className="hide-on-med-and-down">
                    <div className="nav-wrapper center" style={{display:'flex', justifyContent:'center'}}>

                        
                            <li><NavLink to="/" style={{fontSize:18}}>Accueil</NavLink></li>
                        

                        
                            <li><NavLink to="/restaurant" style={{fontSize:18}}>Le restaurant</NavLink></li>
                        

                        
                            <li className='dropdown-trigger' data-target='dropdown1'><NavLink to="/menu" style={{fontSize:18,}}>Les menus<i className="small material-icons right">arrow_drop_down
                            </i></NavLink></li>
                        

                        <ul id='dropdown1' className='dropdown-content'>
                            <li><NavLink to="/menu" style={{fontSize:18}} className="green-text">Menus</NavLink></li>
                            <li><NavLink to="/entree" style={{fontSize:18}} className="green-text">Entrées</NavLink></li>
                            <li><NavLink to="/plat" style={{fontSize:18}} className="green-text">Plats</NavLink></li>
                            <li><NavLink to="/suggestion" style={{fontSize:18}} className="green-text">Suggestions</NavLink></li>
                            <li><NavLink to="/pizza" style={{fontSize:18}} className="green-text">Pizzas</NavLink></li>
                        </ul>

                        
                            <li><NavLink to="/menuSaisonnier" style={{fontSize:18}}>Menus saisonniers</NavLink></li>
                        
                        
                            <li><NavLink to={{pathname: "https://www.ubereats.com/ch-fr/store/mirabel-chez-francis/xKDQyFAwWviwQS16AVJcLQ?diningMode=DELIVERY&ps=1"}} target="_blank" style={{fontSize:18}}>Uber Eats</NavLink></li>
                        
                        
                            <li><NavLink to="/galerie" style={{fontSize:18}}>Galerie photos</NavLink></li>
                        

                        
                            <li><NavLink to="/contact" style={{fontSize:18}}>Contact</NavLink></li>
                        

                        
                        </div>
                </ul>
                
                


                {/* Cache pour les ordinateurs (affichage tablette + portable)*/}
                
                <ul className="sidenav left-align" id="slide-out">
                    <li><NavLink to="/" className="sidenav-close">Accueil</NavLink></li>
                    <li><NavLink to="/restaurant" className="sidenav-close">Le restaurant</NavLink></li>
                        

                    <li className='dropdown-trigger' data-target='dropdown2'><NavLink to="/menu">Les menus<i className="small material-icons right">arrow_drop_down
                    </i></NavLink></li>

                    
                    

                    <ul id='dropdown2' className='dropdown-content sidenav-close'>
                        <li><NavLink to="/menu">Les menus</NavLink></li>
                        <li><NavLink to="/entree">Entrée</NavLink></li>
                        <li><NavLink to="/plat">Plat</NavLink></li>
                        <li><NavLink to="/suggestion">Suggestion</NavLink></li>
                        <li><NavLink to="/pizza">Pizza</NavLink></li>
                    </ul>

                    <li><NavLink to="/menuSaisonnier" className="sidenav-close">Menus saisonniers</NavLink></li>
                    <li><NavLink to={{pathname: "https://www.ubereats.com/ch-fr/store/mirabel-chez-francis/xKDQyFAwWviwQS16AVJcLQ?diningMode=DELIVERY&ps=1"}} target="_blank" className="sidenav-close">Uber Eats</NavLink></li>
                    <li><NavLink to="/galerie" className="sidenav-close">Galerie photos</NavLink></li>
                    <li><NavLink to="/contact" className="sidenav-close">Contact</NavLink></li>

                    
                </ul>
                
            
            
             { /*<NavLink to="/">
                <img src={LogoMirabel} alt="Logo" className="logo" />
            </NavLink>
             */ }

             
        </nav>
        
    )
}
}

export default Navbar;