import React, { Component } from 'react';
import Contact2 from '../images/batiment2.jpg';
import Contact3 from '../images/RestaurantNonCoupe.jpeg';
import M from 'materialize-css/dist/js/materialize.min.js';
import MyMapComponent from './MyMapComponent';

class Contact extends Component {

    componentDidMount() {
        let materialboxed = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(materialboxed);
      }

    render(){
        return (
            <div>
                  <MyMapComponent isMarkerShown={true} /> 
                
                <div className="container">

                    <div className="green lighten-5" style={{borderRadius: 35, paddingBottom: 15}}>

                        <h4 className="center green-text text-darken-2" style={{paddingTop: 15, paddingLeft: 15, paddingRight: 15}}>Vous souhaitez réserver une table ou notre carnotzet ? </h4>

                        <h5 className="green-text text-darken-2" style={{paddingLeft: 15, paddingRight: 15, paddingBottom: 15}}>Vous désirez commander un repas à l'emporter ? </h5>

                        <button className="btn green darken-2 waves-effect waves-dark">Appelez-nous!</button>

                    </div>

                    <h5 className="flow-text left-align green-text text-darken-2" style={{fontSize: 30}}>Où nous trouver ?</h5>

                    <p className="left-align textBase">Notre restaurant se trouve au coeur du village d'Ependes (VD) à 4km seulement d'Yverdon-les-Bains.</p>


                    <div style={{
                        display: 'flex',
                    }}>

                        <i className="small material-icons left">directions_subway</i>

                        <span className="left-align textBase" style={{ paddingBottom: 15}}>
                            A 300m de la gare d'Ependes
                        </span>

                    </div>

                    <div style={{
                        display: 'flex',
                    }}>

                        <i className="small material-icons left">directions_car</i>

                        <span className="left-align textBase" style={{ paddingBottom: 15}}>   
                            Places de parc directement devant le restaurant ainsi que sur le parking de la Grande Salle du village à 2 minutes de marche
                        </span>

                    </div>


                    <div style={{
                        display: 'flex',
                    }}>

                        <i className="small material-icons left">business</i>

                        <span className="left-align textBase" style={{ paddingBottom: 15}}>
                            Mirabel Chez Francis, Route d'Yverdon 14, 1434 Ependes VD
                        </span>

                    </div>


                    <div style={{
                        display: 'flex',
                    }}>

                        <i className="small material-icons left">local_phone</i>
                        
                        <span className="left-align textBase" style={{ paddingBottom: 15}}>                           
                            +41 24 441 73 66
                        </span>

                    </div>


                    <div style={{
                        display: 'flex',
                    }}>

                        <i className="small material-icons left">email</i>

                        <span className="left-align textBase" style={{ paddingBottom: 15}}>
                            mirabelchezfrancis@gmail.com
                        </span>

                    </div>

                    
                    <br></br>
                    <h5 className="flow-text left-align green-text text-darken-2">Horaires d'ouverture</h5>

                    {/* Affichage tablette + téléphone*/}
                    <div className="hide-on-large-only">
                    <table className="highlight">
                        <tbody>

                            <tr>
                                <td className="left-align textBase">Dimanche</td>
                                <td className="right-align textBase">9h00 - 14h00  <br/> 18h00 - 23h00</td>
                            </tr>

                            <tr>
                                <td className="left-align textBase">Lundi <br/> Mardi</td>
                                <td className="right-align textBase">8h45 - 14h00</td>
                            </tr>
                            
                            <tr>
                                <td className="left-align textBase">Mercredi <br/> Jeudi <br/> Vendredi</td>
                                <td className="right-align textBase">8h45 - 14h00 <br/> 18h00 - 23h00</td>
                            </tr>

                            <tr>
                                <td className="left-align textBase">Samedi</td>
                                <td className="right-align textBase">9h00 - 14h00 <br/> 18h00 - 23h00</td>
                            </tr>
                        </tbody>
                    </table>
                    <br></br>
                    </div>



                    {/* Affichage ordinateur*/}
                    <div className="hide-on-med-and-down">
                    <table className="highlight">
                        <tbody>

                            <tr>
                                <td className="left-align textBase">Dimanche</td>
                                <td className="right-align textBase">9h00 - 14h00  <br/> 18h00 - 23h00</td>
                            </tr>

                            <tr>
                                <td className="left-align textBase">Lundi - Mardi</td>
                                <td className="right-align textBase">8h45 - 14h00</td>
                            </tr>
                            
                            <tr>
                                <td className="left-align textBase">Mercredi - Jeudi - Vendredi</td>
                                <td className="right-align textBase">8h45 - 14h00 <br/> 18h00 - 23h00</td>
                            </tr>

                            <tr>
                                <td className="left-align textBase">Samedi</td>
                                <td className="right-align textBase">9h00 - 14h00 <br/> 18h00 - 23h00</td>
                            </tr>
                        </tbody>
                    </table>
                    <br></br>
                    </div>


                    
                    <h5 className="flow-text left-align green-text text-darken-2">Langues</h5>

                    <div style={{
                        display: 'flex',
                    }}>

                        <i className="small material-icons left">flag</i>

                        <span className="left-align textBase" style={{ paddingBottom: 15}}>
                        Nous parlons français, allemand, anglais et tamoul
                        </span>

                    </div>


                    <br></br>
                    <h5 className="flow-text left-align green-text text-darken-2">Moyens de paiement</h5>

                    <div style={{
                        display: 'flex',
                    }}>

                        <i className="small material-icons left">credit_card</i>

                        <span className="left-align textBase" style={{ paddingBottom: 15}}>
                        Nous acceptons vos paiements en espèces, par carte de crédit (Mastercard, Visa), carte de débit ou Postfinance.
                        </span>

                    </div>


                    {/* Affichage tablette + téléphone*/}
                    <div className="hide-on-large-only">
                        <div className="row">
                            <div className="col s12 m6">
                                <img src={Contact3} alt="Batiment" className="responsive-img materialboxed" /><br/>
                            </div>

                            <div className="col s12 m6">
                                <img src={Contact2} alt="Table" className="responsive-img materialboxed" />
                            </div>
                        </div>
                    </div>

                    {/* Affichage ordinateur*/}
                    <div className="hide-on-med-and-down">
                        <div className="row">
                            <div className="col l6">
                                <img src={Contact3} alt="Batiment" className="responsive-img materialboxed" />
                            </div>

                            <div className="col l6">
                                <img src={Contact2} alt="Table" className="responsive-img materialboxed" />
                            </div>
                        </div>
                    </div>

                    

                </div>
            </div>
        )
}
}


export default Contact;