import React, { Component } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import TestLogo from './components/TestLogo';
import Footer from './components/Footer';
import { BrowserRouter, Route } from 'react-router-dom';
import Accueil from './components/Accueil';
import MenuSaisonnier from './components/MenuSaisonnier';
import Contact from './components/Contact';
import Entree from './components/Entree';
import Galerie from './components/Galerie';
import Menu from './components/Menu';
import Pizza from './components/Pizza';
import Plat from './components/Plat';
import Restaurant from './components/Restaurant';
import Suggestion from './components/Suggestion';
import Evenement from './components/Evenement';
import ScrollToTop from './components/ScrollToTop';



class App extends Component {

  render() {
    return (
      <BrowserRouter>

        {/* Composant qui permet de scroller automatiquement en haut de chaque page quand on navigue */}
        <ScrollToTop />
        
        <div className="App">
          <TestLogo />
          <Navbar />
                  
          

          <Route exact path='/' component={Accueil}  />
          <Route path='/MenuSaisonnier' component={MenuSaisonnier} />
          <Route path='/Contact' component={Contact} />
          <Route path='/Entree' component={Entree} />
          <Route path='/Galerie' component={Galerie} />
          <Route path='/Menu' component={Menu} />
          <Route path='/Pizza' component={Pizza} />
          <Route path='/Plat' component={Plat} />
          <Route path='/Restaurant' component={Restaurant} />
          <Route path='/Suggestion' component={Suggestion} />
          <Route path='/Evenement' component={Evenement} />

          <Footer />

        </div>
      </BrowserRouter>
    );
  }
}

export default App;
