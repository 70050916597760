import React from 'react';
import Crevette from '../images/imageCrevettes.jpeg';
import { NavLink } from 'react-router-dom';

const Plat = () => {
    return (
        <div>
        <div  style={{ backgroundImage:`url(${Crevette})`, backgroundSize: 'cover' }}>
        <br/>
            <div className="container">
            <div className="green lighten-5" style={{borderRadius: 35, paddingBottom: 15}}>

                
                <h4 className="center green-text text-darken-2" style={{paddingTop: 15}}>Les plats</h4>

                <h5 className="flow-text green-text text-darken-2">Pour rassasier votre appétit</h5>

                <table className="highlight">
                    <tbody>

                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Steak de boeuf</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 22.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Entrecôte de boeuf</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 29.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Steak de cheval</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 20.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Entrecôte de cheval</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 28.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Tartare de boeuf</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 29.50</td>
                        </tr>



                        <tr>
                            <td className="left-align textBase" colSpan="10" style={{ fontWeight: "bold", paddingLeft: 15}}>Sauces aux choix</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Poivre vert</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 4.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Champignons</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 4.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Echalote</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 4.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Morilles</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 7.00</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Café de Paris</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 5.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" colSpan="10" style={{ fontWeight: "bold", paddingLeft: 15}}>Nos plats sont servis avec salade, riz basmati, pâtes ou frites.</td>
                        </tr>


                    </tbody>
                </table>



                <h5 className="flow-text green-text text-darken-2">Pour découvrir nos trésors</h5>

                <table className="highlight">
                    <tbody>

                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Filets de perche (meunière)</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 26.50</td>
                        </tr>
                    </tbody>
                </table>



                <h5 className="flow-text green-text text-darken-2">Pour délecter votre palais</h5>

                <table className="highlight">
                    <tbody>

                        <tr>
                            <td className="left-align textBase" colSpan="10" style={{ fontWeight: "bold", paddingLeft: 15}}>Spaghetti, rigatoni ou fettucine</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Bolognese</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 16.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Carbonara</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 17.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Morilles</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 24.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Au saumon ou aux crevettes</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 21.50</td>
                        </tr>
                    </tbody>
                </table>

  

                <h5 className="flow-text" style={{ color: "#43a047"}}>Pour les enfants</h5>

                <table className="highlight">
                    <tbody>

                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Pâtes carbonara</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 12.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Jambon-frites</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 9.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Steak-frites</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 14.50</td>
                        </tr>
                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Portion de frites</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 5.50</td>
                        </tr>

                    </tbody>
                </table>


                <h5 className="flow-text" style={{ color: "#43a047"}}>Nos plats végétariens</h5>

                <table className="highlight">
                    <tbody>

                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Pâtes Mirabel (sauce tomates, légumes à choix, poivrons et oignons)</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 16.00</td>
                        </tr>

                        <tr>
                            <td className="left-align textBase" style={{paddingLeft: 15}}>Lasagne végétarienne</td>
                            <td className="right-align textBase" style={{paddingRight: 15}}>CHF 18.50</td>
                        </tr>

                    </tbody>
                </table>

                <br/><br/>

                <NavLink to="/suggestion">
                    <button className="btn green darken-2 waves-effect waves-dark">Voir les suggestions du chef</button>
                </NavLink>

                
                <br/><br/>


            </div>
            <br/><br/>
            </div>
            </div>

        
        </div>
    )
}

export default Plat;