import React from 'react';
import { NavLink } from 'react-router-dom';
import Crevette from '../images/imageCrevettes.jpeg';

const Suggestion = () => {
    return (
        <div>
        <div  style={{ backgroundImage:`url(${Crevette})` }}>
        <br/>
            <div className="container">
                <div className="green lighten-5" style={{borderRadius: 35, paddingBottom: 15}}>
                
            
                    <h4 className="center green-text text-darken-2" style={{paddingTop: 15}}>Suggestions du chef</h4>

                    <table className="highlight">
                            <tbody>

                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Emincé de boeuf Thaïlandaise</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 29.50</td>
                                </tr>

                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Magret de canard, miel au citron vert</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 29.50</td>
                                </tr>

                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Emincé de poulet à l'Indienne</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 28.50</td>
                                </tr>
                                
                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Emincé de poulet citronnelle et noix de coco</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 28.50</td>
                                </tr>

                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Emincé de poulet aux deux curry</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 28.50</td>
                                </tr>
                                
                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Emincé de poulet Thaïlandaise</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 28.50</td>
                                </tr>
                                
                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Filet d'agneau au curry des îles</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 29.50</td>
                                </tr>
                                
                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Gambas à l'Indienne</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 29.50</td>
                                </tr>
                                
                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Gambas Thaïlandaise</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 29.50</td>
                                </tr>
                                
                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Gambas au curry rouge</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 28.50</td>
                                </tr>
                                
                                <tr>
                                    <td className="left-align textBase" style={{paddingLeft: 15}}>Gambas mode du chef provençal</td>
                                    <td className="right-align textBase" style={{paddingRight: 15}}>CHF 25.50</td>
                                </tr>

                            </tbody>
                    </table>
                    
                    <p className="flow-text">Nos plats sont servis avec salade, riz basmati, pâtes ou frites.</p>
            
                    <NavLink to="/pizza">
                        <button className="btn green darken-2 waves-effect waves-dark">Voir les pizzas</button>
                    </NavLink>

                    
        <br /><br />
            
            </div>
            <br /><br />

            </div>
            
            
        </div>

        

        </div>
    )
}

export default Suggestion;