import React from 'react';

const Footer = () => {
    return (
        <footer className="page-footer green darken-2">
          <div className="container">

            <div className="row">        
              
              <div className="col l6 s12">

                <p className="grey-text text-lighten-4">Mirabel Chez Francis</p>
                <p className="grey-text text-lighten-4">Route d'Yverdon 14</p>
                <p className="grey-text text-lighten-4">1434 Ependes VD</p>

              </div>
              <div className="col l6 s12">

                <p className="grey-text text-lighten-4">+41 24 441 73 66</p>
                <p className="grey-text text-lighten-4">mirabelchezfrancis@gmail.com</p>

              </div>
            </div>
          </div>
          <div className="footer-copyright" style={{ backgroundColor:"#211e1f" }}>
            <div className="container center-align">
            © 2022 Copyright Text
            </div>
          
          </div>
          
        </footer>
    )
}

export default Footer;
