import React, { Component } from 'react';
import Galerie3 from '../images/galeriephotos3.jpg';
import Galerie4 from '../images/galeriephotos4.jpg';
import Galerie5 from '../images/galeriephotos5.jpg';
import Galerie6 from '../images/galeriephotos6.jpg';
import Galerie7 from '../images/galeriephotos7.jpg';
import Galerie8 from '../images/galeriephotos8.jpg';
import Galerie9 from '../images/galeriephotos9.jpg';
import Galerie10 from '../images/galeriephotos10.jpg';
import Galerie11 from '../images/galeriephotos11.jpg';
import Galerie12 from '../images/galeriephotos12.jpg';
import Galerie13 from '../images/galeriephotos13.jpg';
import Galerie14 from '../images/galeriephotos14.jpg';
import Galerie15 from '../images/galeriephotos15.jpg';
import Buffet3 from '../images/buffet3.jpg';
import Buffet4 from '../images/buffet4.jpg';
import M from 'materialize-css/dist/js/materialize.min.js';

class Galerie extends Component {

    componentDidMount() {
        let materialboxed = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(materialboxed);
      }

    render(){
        return (
            <div className="container">
                <div className="green lighten-5" style={{borderRadius: 35}}>
                    <h4 className="center green-text text-darken-2" style={{paddingTop: 15}}>Aperçu du restaurant</h4>

                    <p className="textBase" style={{paddingLeft: 15, paddingRight: 15}}>
                    Notre établissement est composé du café (30 places), de la salle à manger (20 places) ainsi que du carnotzet.
                    </p>
                    <p style={{ fontSize: 18, paddingBottom: 15, paddingLeft: 15, paddingRight: 15}}>Venez-nous rendre visite!</p>
                    
                </div>


                <div className="row">

                    <div className="col s6 l3">
                        <img src={Galerie3} alt="Restaurant" className="responsive-img materialboxed"/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie4} alt="Restaurant" className="responsive-img materialboxed"/><br/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie5} alt="Restaurant" className="responsive-img materialboxed"/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie6} alt="Restaurant" className="responsive-img materialboxed"/><br/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie7} alt="Restaurant" className="responsive-img materialboxed"/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie8} alt="Restaurant" className="responsive-img materialboxed"/><br/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie9} alt="Restaurant" className="responsive-img materialboxed"/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie10} alt="Restaurant" className="responsive-img materialboxed"/><br/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie11} alt="Restaurant" className="responsive-img materialboxed"/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie12} alt="Restaurant" className="responsive-img materialboxed"/><br/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie13} alt="Restaurant" className="responsive-img materialboxed"/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie14} alt="Restaurant" className="responsive-img materialboxed"/><br/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Galerie15} alt="Restaurant" className="responsive-img materialboxed"/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Buffet3} alt="Buffet3" className="responsive-img materialboxed"/><br/>
                    </div>

                    <div className="col s6 l3">
                        <img src={Buffet4} alt="Buffet4" className="responsive-img materialboxed"/>
                    </div>

                </div>

            </div>
        )
    }
}

export default Galerie;