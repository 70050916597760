import React, { Component } from 'react';
import PhotoChef from '../images/PhotoChef.jpeg';
import { NavLink } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';
import Guru from './Guru';

class Restaurant extends Component {

    componentDidMount() {
        let materialboxed = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(materialboxed);
      }

    render(){

    
    return (
        
        <div className="container">

            <h4 className="center-align green lighten-5 green-text text-darken-2" style={{borderRadius: 35, paddingTop: 10, paddingBottom: 10}}>Du Sri Lanka à la Suisse</h4>



                    <h5 className="flow-text left-align green-text text-darken-2">Le restaurant et son chef</h5>

                    <p className="textBase" style={{ textAlign: 'justify'}}>Originaire du Sri Lanka, M. Kangatharan est arrivé en Suisse à l'âge de 20 ans et a entamé sa formation à l'école hôtelière César Ritz au Bouveret (Valais) en 1990. Celle-ci s'est achevée après deux ans de stage à l'Hôtel Mövenpick à Ouchy (Vaud). <br /><br />

                    Après l'obtention de son diplôme, M. Kangatharan a travaillé au Beau-Rivage Palace à Ouchy en tant que responsable du mini-bar.
                    Il a également occupé la fonction de maître d'hôtel pour le restaurant du Théatre municipal de Lausanne.
                    Après avoir complété son expérience au Grand Hôtel des bains d'Yverdon, M. Kangatharan ouvre son propre restaurant en 2002.<br /><br />

                    Il tiendra l'Hôtel L'Orchidée - Restaurant Café des Amis à Essert-Pittet pendant 13 ans, jusqu'en mars 2015.<br />
                    En fin de bail, il se dirige avec son équipe vers le village d'à côté et reprend l'ancien "Guillaume Tell Chez Francis", rebaptisé "Mirabel Chez Francis".<br /><br />

                    Sa cuisine est plutôt traditionnelle avec des plats d'ici mais vous fera voyager au-delà des frontières si vous goûtez aux spécialités asiatiques et indiennes proposées.<br /><br />

                    Les pizzas complètent l'offre de l'établissement.</p>

                    <br/>

                    <h5 className="flow-text left-align green-text text-darken-2">Récompenses</h5>

                    <p className="textBase left-align" style={{ textAlign: 'justify'}}>
                    Nous sommes ravis de présenter ce Certificat d'Excellence à Mirabel Chez Francis
                    et à toute son équipe.
                    Nous avons obtenu cette récompense grâce aux retours positifs
                    de nos clients ainsi que d'excellentes notes.
                    </p>
                    
                    <Guru/>


                    <br/>
                    <h5 className="flow-text left-align green-text text-darken-2">Infos pratiques</h5>

                    <p className="textBase left-align" style={{ textAlign: 'justify'}}>Le restaurant à l'ambiance conviviale et familiale a une capacité d'accueil de 50 places ainsi que 50 autres supplémentaires dans son carnotzet. Celui-ci peut-être réservé pour des anniversaires, repas de familles ou toutes autres rencontres 
                    <NavLink to="/contact">(contacter l'établissement)</NavLink>.<br /><br />

                    Notre établissement bénéficie également d'une belle terrasse permettant de déguster nos plats au soleil lors des beaux jours.
                    Les clients fidèles ainsi que les nouveaux visiteurs se retrouvent dans ce lieu à proximité d'Yverdon (4km).<br /><br />

                    Une dizaine de places de parc se trouvent directement devant le bâtiment et le parking de la Grande Salle de la commune se trouve à quelques pas seulement.<br /></p>
                    <br/>

            {/* Affichage tablette + téléphone*/}
            <div className="hide-on-large-only"><br/>        

                <div className="row">

                    <div className="col s12">        

                        <NavLink to="/entree">
                            <button className="btn green darken-2 waves-effect waves-dark">Voir la carte complète!</button>
                        </NavLink>

                    </div>

                </div>

                <div className="row">

                    <div className="col s12"> 

                        <NavLink to="/contact">
                            <button className="btn green darken-2 waves-effect waves-dark">Réserver une table</button>
                        </NavLink>

                    </div>

                </div>

            </div>


        {/* Affichage ordinateur*/}
        <div className="hide-on-med-and-down">
        <div className="row">

                    <div className="col l4 offset-l1 s6">        

                        <NavLink to="/entree">
                            <button className="btn green darken-2 waves-effect waves-dark">Voir la carte complète!</button>
                        </NavLink>

                    </div>

                    <div className="col l4 offset-l2 s6"> 

                        <NavLink to="/contact">
                            <button className="btn green darken-2 waves-effect waves-dark">Réserver une table</button>
                        </NavLink>

                    </div>

                </div>
        
        </div>

            <p></p>


            <div className="row">

                <div className="col s8 offset-s2 center">

                    <img src={PhotoChef} alt="Cuisinier" className="responsive-img materialboxed"/>

                </div>

            </div>


        </div>
    )
}
}

export default Restaurant;