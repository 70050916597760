import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import LogoMirabel from '../images/LogoMirabel.png';

class TestLogo extends Component {
    render() {
        return (
            <div style={{ backgroundColor:"#211e1f", height:"150px"  }}>
            
                <NavLink to="/">
                    <img src={LogoMirabel} alt="Logo" className="logo" style={{height:"100%"}} />
                </NavLink>
            </div>
            
        )
    }
    }
    
export default TestLogo;