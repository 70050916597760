import React, { Component } from 'react';
import Menu1 from '../images/image_menu1.jpg';
import Menu2 from '../images/image_menu2.jpg';
import Menu3 from '../images/image_menu3.jpg';
import { NavLink } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';


class Menu extends Component {

    componentDidMount() {
        let materialboxed = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(materialboxed);
      }

    render(){
    return (
        <div className="container">

            <div className="green lighten-5" style={{borderRadius: 35}}>

                <h4 className="center green-text text-darken-2" style={{paddingTop: 15}}>Tradition & cuisine d'ailleurs</h4>
                
                <p className="textBase" style={{paddingLeft: 15, paddingRight: 15, paddingBottom: 15}}>Notre carte se compose de plats traditionnels suisses ainsi que de spécialités indiennes et asiatiques. <br />
                Elle est régulièrement mise à jour selon les saisons.

                Nous proposons aussi quelques pizzas.<br /><br />
                
                Tous nos plats peuvent être pris à l'emporter : vous pouvez passer commande en nous appelant ou directement au restaurant - le temps d'attente est d'environ 20 minutes.</p>
                
            </div>

            <div className="green lighten-5" style={{borderRadius: 35}}>

                <h4 className="center green-text text-darken-2" style={{paddingTop: 15}}>Plats à l'emporter</h4>
                
                <p className="textBase" style={{paddingBottom: 15, paddingLeft: 15, paddingRight: 15}}>A titre d'information, tous les plats proposés dans ce restaurant sont également disponibles à emporter.<br/>
                Les plats à l'emporter comportent un rabais de 10% pour tous les plats
                à la carte ainsi que 2 CHF de rabais pour les pizzas à l'emporter.<br/>
                Toutefois, la livraison est assurée uniquement sur place.</p>
                
            </div><br />
            
            {/* Affichage ordinateur*/}
            <div className="hide-on-med-and-down">
                <div className="row">

                    <div className="col l4 offset-l1 s6">

                        <NavLink to="/contact">
                            <button className="btn green darken-2 waves-effect waves-dark">Réserver une table</button>
                        </NavLink>

                    </div>

                    <div className="col l4 offset-l2 s6">

                        <NavLink to="/entree">
                            <button className="btn green darken-2 waves-effect waves-dark">Voir la carte complète</button>
                        </NavLink><br /><br />

                    </div>

                </div>
            </div>



            {/* Affichage tablette + téléphone*/}
            <div className="hide-on-large-only">
                <div className="row">

                    <div className="col s12">

                        <NavLink to="/contact">
                            <button className="btn green darken-2 waves-effect waves-dark">Réserver une table</button>
                        </NavLink>

                    </div>

                </div>

                <div className="row">

                    <div className="col s12">

                        <NavLink to="/entree">
                            <button className="btn green darken-2 waves-effect waves-dark">Voir la carte complète</button>
                        </NavLink><br /><br />

                    </div>

                </div>
            </div>

            <div className="row">

                <div className="col s6 l4">

                    <img src={Menu1} alt="Poulet" className="responsive-img materialboxed"/>
                    

                </div>

                <div className="col s6 l4">

                    <img src={Menu2} alt="Poulet" className="responsive-img materialboxed"/><br/>
                    

                </div>

                <div className="col s6 l4 offset-s3">

                    
                    <img src={Menu3} alt="Pizza" className="responsive-img materialboxed"/>

                </div>

            </div>

        </div>
    )
}
}

export default Menu;