import React from 'react';
import { NavLink } from 'react-router-dom';
import Riz from '../images/riz.jpg';

const Entree = () => {
    return (
        <div style={{ backgroundImage:`url(${Riz})` }}>
        <br/>
            <div className="container">

                <div className="green lighten-5" style={{borderRadius: 35, paddingBottom: 35}}>

                    <h4 className="center green-text text-darken-2" style={{paddingTop: 15}}>Les entrées</h4>

                    <h5 className="flow-text green-text text-darken-2">Pour aiguiser votre appétit</h5>

                    <table className="highlight">
                        <tbody>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Salade verte (petite)</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 4.00</td>
                            </tr>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Salade verte (grande)</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 6.00</td>
                            </tr>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Salade mêlée (petite)</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 5.50</td>
                            </tr>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Salade mêlée (grande)</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 12.00</td>
                            </tr>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Consommé nature</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 4.50</td>
                            </tr>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Consommé à l'oeuf</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 5.00</td>
                            </tr>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Consommé au porto</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 5.50</td>
                            </tr>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Cocktail de crevettes</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 13.00</td>
                            </tr>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Crevettes à l'ail</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 12.00</td>
                            </tr>

                            <tr>
                                <td colSpan="10" className="left-align textBase" style={{paddingLeft: 15}}>Rouleaux de printemps</td>
                                <td colSpan="10" className="right-align textBase" style={{paddingRight: 15}}>CHF 14.00</td>
                            </tr>

                        </tbody>
                    </table>

                    <br/><br/>
                    <NavLink to="/plat">
                        <button className="btn green darken-2 waves-effect waves-dark">Voir les propositions de plats</button>
                    </NavLink>
                </div>

                <br />
            
                


                <br /><br />

            </div>
            
        </div>
    )
}

export default Entree;