import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import Buffet from '../images/buffet.jpeg';
import Buffet2 from '../images/buffet2.jpeg';
import emailjs from 'emailjs-com';
import Modal from 'react-modal';


// State initial pour le formulaire
const initialState = {
    prenom: "",
    nom: "",
    phone: "",
    email: "",
    prenomError: "",
    nomError: "",
    phoneError: "",
    emailError: ""
}


// Style pour la boîte modale
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    textAlign             : 'center',
    backgroundColor       : '#e8f5e9',
    border                : '2px solid'
  }
};

class Evenement extends Component {


  state = initialState;


  // Permet de prendre en compte les modifications réalisées dans les champs du formulaire.
  handleChange = event => {
    const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value
    });
  };

  // Fonction pour valider le formulaire
  validate = () => {
    let prenomError = "";
    let nomError = "";
    let emailError = "";

    if (!this.state.nom) {
      nomError = "Ce champ ne peut pas être vide";
    }

    if (!this.state.prenom) {
      prenomError = "Ce champ ne peut pas être vide";
    }

    if (!this.state.email) {
      emailError = 'Votre adresse mail est invalide';
    }

    if (emailError || nomError || prenomError){
      this.setState({emailError, nomError, prenomError});
      return false;
    }

    return true;
  };



  handleSubmit = event => {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid){

      this.setState({ showModal: true });

      // Envoie le mail
      emailjs.sendForm('service_11watlr', 'template_sj89qrf', event.target, 'user_qQOT5QYIZNiX0dgJsOt28');
      
      // Clear le formulaire s'il est valide, après l'avoir envoyé
      this.setState(initialState);
    }

  };



  constructor () {
    super();
    this.state = {
      showModal: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  


  componentDidMount() {
    let elems = document.querySelector('.autocomplete');
    M.Autocomplete.init(elems, {});

    let materialboxed = document.querySelectorAll('.materialboxed');
    M.Materialbox.init(materialboxed);

  }

    render() {
        return (
          
            <div className="container">

              
                <Modal 
                  isOpen={this.state.showModal}
                  style={customStyles}
                >
                  
                  <p className="textBase"  style={{fontSize:24}}>Vous êtes à présent inscrit à notre newsletter !</p>
                   
                  <button onClick={this.handleCloseModal} className="btn green darken-2">Confirmer</button>
                  
                
                </Modal>
               

              

            
            
                        
                <div className="green lighten-5" style={{borderRadius: 35}}>
                  <h4 className="center green-text text-darken-2" style={{paddingTop: 15}}>Evénements</h4>
                  <p className="textBase" style={{paddingLeft: 15, paddingRight: 15, paddingBottom: 15}}>Inscrivez-vous à notre newsletter pour vous tenir au courant des événements à venir !</p>

                </div>

                <form className="contact-form" onSubmit={this.handleSubmit}>

                <div className="row">
                <div className="col s12">
                  <div className="row">
                    <div className="input-field col s12">

                      <i className="material-icons prefix">account_circle</i>

                      <input
                        id="icon_prefix"
                        type="text"
                        className="validate"
                        name="prenom"
                        value={this.state.prenom}
                        onChange={this.handleChange}
                        placeholder="Prénom"
                      />

                      <div style={{ color:'red', fontWeight: 'bold'}}>
                        {this.state.prenomError}
                      </div>
                      
                      
                    </div>

                    <div className="input-field col s12">

                      <i className="material-icons prefix">account_circle</i>

                      <input 
                        id="icon_prefix"
                        type="text"
                        className="validate"
                        name="nom"
                        value={this.state.nom}
                        onChange={this.handleChange}
                        placeholder="Nom"
                      />

                      <div style={{ color:'red', fontWeight: 'bold'}}>
                        {this.state.nomError}
                      </div>

                      
                    </div>

                    

                    <div className="input-field col s12">

                      <i className="material-icons prefix">phone</i>

                      <input
                        id="icon_telephone"
                        type="tel"
                        className="validate"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        placeholder="Téléphone (optionnel)"
                      />                      

                    </div>

                    <div className="input-field col s12">

                      <i className="material-icons prefix">email</i>

                      <input
                        id="icon_email"
                        name="email"
                        className="validate"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="Adresse e-mail"
                      />

                      <div style={{ color:'red', fontWeight: 'bold'}}>
                        {this.state.emailError}
                      </div>

                      
                      <span className="helper-text" data-error="E-mail incorrect" data-success="E-mail correct"></span>


                    
                    </div>
                  </div>
                </div>
              </div>

              <div className="input-field center">
                <button className="btn green darken-2" type="submit" value="Send">S'inscrire</button>
              </div>

              </form>

              {/* Affichage ordinateur*/}
              <div className="hide-on-med-and-down">
                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                      <div className="col l7">
                          <img src={Buffet} alt="Buffet" className="responsive-img materialboxed" />
                      </div>
                      

                      <div className="col l5">
                          <img src={Buffet2} alt="Buffet2" className="responsive-img materialboxed" />
                      </div>
                  </div>
                </div>

              {/* Affichage tablette + téléphone*/}
              <div className="hide-on-large-only">

                <div className="row">
                      <div className="col s12">
                          <img src={Buffet} alt="Buffet" className="responsive-img materialboxed" /><br/>
                      </div>
                      

                      <div className="col s12">
                          <img src={Buffet2} alt="Buffet2" className="responsive-img materialboxed" />
                      </div>
                </div>
              </div>


            </div>
        )
    }
}

export default Evenement;